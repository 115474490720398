import { ProtocxCoreError } from './base';

export class ResourceAlreadyExistsError extends ProtocxCoreError {
  static code = 'RESOURCE_ALREADY_EXISTS';

  constructor(message: string) {
    super(message, ResourceAlreadyExistsError.code);
    this.name = 'ResourceAlreadyExistsError';
  }
}

export class InvalidInput extends ProtocxCoreError {
  static code = 'INVALID_INPUT';

  constructor(message: string) {
    super(message, InvalidInput.code);
    this.name = 'INVALID_INPUT';
  }
}

export class ResourceNotFound extends ProtocxCoreError {
  static code = 'RESOURCE_NOT_FOUND';

  constructor(message: string) {
    super(message, ResourceNotFound.code);
    this.name = 'ResourceNotFound';
  }
}

export class QuotaExceeded extends ProtocxCoreError {
  static code = 'QUOTA_EXCEEDED';

  constructor(message: string) {
    super(message, QuotaExceeded.code);
    this.name = 'QuotaExceeded';
  }
}

export class PermissionDenied extends ProtocxCoreError {
  static code = 'PERMISSION_DENIED';

  constructor(message: string) {
    super(message, PermissionDenied.code);
    this.name = 'PermissionDenied';
  }
}

export class ProductRequired extends ProtocxCoreError {
  static code = 'PRODUCT_REQUIRED';

  constructor(message: string) {
    super(message, ProductRequired.code);
    this.name = 'ProductRequired';
  }
}

export class RolesHasTeamsException extends ProtocxCoreError {
  static code = 'ROLE_HAS_TEAMS';

  constructor(message: string) {
    super(message, RolesHasTeamsException.code);
    this.name = 'RolesHasTeamsException';
  }
}

export class RolesHasUsersException extends ProtocxCoreError {
  static code = 'ROLE_HAS_USERS';

  constructor(message: string) {
    super(message, RolesHasUsersException.code);
    this.name = 'RolesHasUsersException';
  }
}

export class UserExistsException extends ProtocxCoreError {
  static code = 'USER_EXISTS';

  constructor(message: string) {
    super(message, UserExistsException.code);
    this.name = 'UserExistsException';
  }
}

export class UserNotFoundException extends ProtocxCoreError {
  static code = 'USER_NOT_FOUND';

  constructor(message: string) {
    super(message, UserNotFoundException.code);
    this.name = 'UserNotFoundException';
  }
}

export class InvitationException extends ProtocxCoreError {
  static code = 'INVITATION_ERROR';

  constructor(message: string) {
    super(message, InvitationException.code);
    this.name = 'InvitationException';
  }
}

export class OperationForbiddenException extends ProtocxCoreError {
  static code = 'OPERATION_FORBIDDEN';

  constructor(message: string) {
    super(message, OperationForbiddenException.code);
    this.name = 'OperationForbiddenException';
  }
}

export class FormatException extends ProtocxCoreError {
  static code = 'FORMAT_ERROR';

  constructor(message: string) {
    super(message, FormatException.code);
    this.name = 'FormatException';
  }
}

export class IpAllowlistUnauthorizedException extends ProtocxCoreError {
  static code = 'IP_ALLOWLIST_UNAUTHORIZED';

  constructor(message: string) {
    super(message, IpAllowlistUnauthorizedException.code);
    this.name = 'IpAllowlistUnauthorizedException';
  }
}

export class WorkspaceRequiresSubcompanyException extends ProtocxCoreError {
  static code = 'WORKSPACE_REQUIRES_SUBCOMPANY';

  constructor(message: string) {
    super(message, WorkspaceRequiresSubcompanyException.code);
    this.name = 'WorkspaceRequiresSubcompanyException';
  }
}

export class TicketCustomerNoEmailException extends ProtocxCoreError {
  static code = 'TICKET_CUSTOMER_NO_EMAIL';

  constructor(message: string) {
    super(message, TicketCustomerNoEmailException.code);
    this.name = 'TicketCustomerNoEmailException';
  }
}

export class InvalidEmailDomainException extends ProtocxCoreError {
  static code = 'INVALID_EMAIL_DOMAIN';

  constructor(message: string) {
    super(message, InvalidEmailDomainException.code);
    this.name = 'InvalidEmailDomainException';
  }
}

export class InvalidFileContentTypeException extends ProtocxCoreError {
  static code = 'INVALID_FILE_CONTENT_TYPE';

  constructor(message: string) {
    super(message, InvalidFileContentTypeException.code);
    this.name = 'InvalidFileContentTypeException';
  }
}

export class ConversationNotEnoughTagsException extends ProtocxCoreError {
  static code = 'CONVERSATION_NOT_ENOUGH_TAGS';

  constructor(message: string) {
    super(message, ConversationNotEnoughTagsException.code);
    this.name = 'ConversationNotEnoughTagsException';
  }
}

export class MagicFreeEmailException extends ProtocxCoreError {
  static code = 'MAGIC_FREE_EMAIL';

  constructor(message: string) {
    super(message, MagicFreeEmailException.code);
    this.name = 'MagicFreeEmailException';
  }
}

export class CustomerAlreadyBannedException extends ProtocxCoreError {
  static code = 'CUSTOMER_ALREADY_BANNED';

  constructor(message: string) {
    super(message, CustomerAlreadyBannedException.code);
    this.name = 'CustomerAlreadyBannedException';
  }
}

export class NoValidBanReasonsException extends ProtocxCoreError {
  static code = 'NO_VALID_BAN_REASONS';

  constructor(message: string) {
    super(message, NoValidBanReasonsException.code);
    this.name = 'NoValidBanReasonsException';
  }
}

export class FailedToCreateCustomerBanException extends ProtocxCoreError {
  static code = 'FAILED_TO_CREATE_CUSTOMER_BAN';

  constructor(message: string) {
    super(message, FailedToCreateCustomerBanException.code);
    this.name = 'FailedToCreateCustomerBanException';
  }
}

export class CustomerIsNotBannedException extends ProtocxCoreError {
  static code = 'CUSTOMER_IS_NOT_BANNED';

  constructor(message: string) {
    super(message, CustomerIsNotBannedException.code);
    this.name = 'CustomerIsNotBannedException';
  }
}

export class EmailTemplateExistsException extends ProtocxCoreError {
  static code = 'EMAIL_TEMPLATE_EXISTS';

  constructor(message: string) {
    super(message, EmailTemplateExistsException.code);
    this.name = 'EmailTemplateExistsException';
  }
}

export class CustomerConversationExistsException extends ProtocxCoreError {
  static code = 'CUSTOMER_CONVERSATION_EXISTS';

  constructor(message: string) {
    super(message, CustomerConversationExistsException.code);
    this.name = 'CustomerConversationExistsException';
  }
}

export class MaxNumConversationsToExportExceededException extends ProtocxCoreError {
  static code = 'MAX_NUM_CONVERSATIONS_TO_EXPORT_EXCEEDED';

  constructor(message: string) {
    super(message, MaxNumConversationsToExportExceededException.code);
    this.name = 'MaxNumConversationsToExportExceededException';
  }
}

export class MaxExportsInitiatedByUserExceeded extends ProtocxCoreError {
  static code = 'MAX_EXPORTS_INITIATED_BY_USER_EXCEEDED';

  constructor(message: string) {
    super(message, MaxExportsInitiatedByUserExceeded.code);
    this.name = 'MaxExportsInitiatedByUserExceeded';
  }
}

export class ConversationAttachmentUnsafeException extends ProtocxCoreError {
  static code = 'CONVERSATION_ATTACHMENT_UNSAFE';

  constructor(message: string) {
    super(message, ConversationAttachmentUnsafeException.code);
    this.name = 'ConversationAttachmentUnsafeException';
  }
}
