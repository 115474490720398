import useSWRImmutable from 'swr/immutable';
import useSWRMutation from 'swr/mutation';
import { MutatorOptions, useSWRConfig } from 'swr';
import { ERROR_BY_CODE } from './exceptions';
import { IpAllowlistUnauthorizedException } from './exceptions/platform';
import { useEffect, useRef, useState } from 'react';
import { SWR_KEYS } from './enums/swr';

export const fetcher = async <ResponseData extends Record<string, any> = Record<string, any>>(
  path: string,
  method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE',
  body?: Record<string, any> | any[] | FormData,
  headers?: Record<string, string>
) => {
  if (!headers) {
    headers = {};
  }

  let _body: any;
  if (body) {
    if (body instanceof FormData) {
      _body = body;
    } else {
      headers['Content-Type'] = 'application/json';
      _body = JSON.stringify(body);
    }
  } else {
    _body = undefined;
  }

  const res = await fetch(`${import.meta.env.VITE_PLATFORM_API_URL}/${path.startsWith('/') ? path.slice(1) : path}`, {
    method,
    headers,
    body: _body,
    credentials: 'include',
  });

  if (!res.ok) {
    /**
     * Here we are going to throw an error based on the response code.
     * See src/lib/exceptions/index.ts for all error types.
     * These should have been registered in platform/exceptions/handlers
     *
     *   - If the error is JSON and has a code that matches one of the registered
     *     error codes, we will throw that error. Use `instanceof` to check the
     *     error type in your request handler.
     *   - If the error is JSON and has a code that does not match one of the
     *     registered error codes, we will throw a generic error.
     *   - If the error is not JSON, we will throw a generic error.
     */
    let errorToThrow: Error;

    const errText = await res.text();
    try {
      const errJson = JSON.parse(errText);
      const { code, detail } = errJson;

      if (code !== undefined && code in ERROR_BY_CODE) {
        errorToThrow = new ERROR_BY_CODE[code](detail, code);

        if (errorToThrow instanceof IpAllowlistUnauthorizedException) {
          window.location.href = '/ip-allowlist-unauthorized';
        }
      } else {
        throw new Error(errText);
      }
    } catch (e) {
      errorToThrow = new Error(errText);
    }

    throw errorToThrow;
  }

  // 204 No Content responses don't have a body
  if (res.status === 204) {
    return {} as ResponseData;
  }

  const parsedData = await res.json();
  return parsedData as ResponseData;
};

export const useFetch = <ResponseData extends Record<string, any> = Record<string, any>>(
  key: string,
  path: string | null,
  headers?: Record<string, string>,
  options?: Parameters<typeof useSWRImmutable<ResponseData>>[2]
) => {
  return useSWRImmutable<ResponseData>(
    path === null ? null : [key, path],
    () => fetcher(path!, 'GET', undefined, headers),
    {
      revalidateOnMount: true,
      ...options,
    }
  );
};

export const useUncachedFetch = <ResponseData extends Record<string, any> = Record<string, any>>(
  path: string | null,
  headers?: Record<string, string>,
  options?: Parameters<typeof useSWRImmutable<ResponseData>>[2]
) => {
  const keyRef = useRef<string>('' + Date.now());
  return useFetch<ResponseData>(keyRef.current, path, headers, options);
};

export const useMutation = <
  const RequestData extends Record<string, any> | any[] | undefined,
  ResponseData extends Record<string, any> = Record<string, any>,
>(
  path: string | null,
  method: 'POST' | 'PUT' | 'DELETE' | 'PATCH',
  headers?: Record<string, string>,
  options?: Parameters<typeof useSWRMutation<ResponseData, any, string, RequestData>>[2]
) => {
  return useSWRMutation<ResponseData, any, string | null, RequestData>(
    path,
    (key, { arg }) => fetcher(key, method, arg, headers),
    options
  );
};

export const useMutate = () => {
  const { mutate } = useSWRConfig();

  const wrappedMutate = <T>(mutateKey: SWR_KEYS, data?: T, opts?: boolean | MutatorOptions<T, any> | undefined) =>
    mutate((key) => Array.isArray(key) && key[0] === mutateKey, data, opts);

  return wrappedMutate;
};

type fetcherSSEEvents = {
  onChunk?: (chunk: string) => void;
  onEnd?: () => void;
};

export const fetcherSSE = async (url: string, body: Record<any, any>, events: fetcherSSEEvents) => {
  const response = await fetch(`${import.meta.env.VITE_PLATFORM_API_URL}/${url}`, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  });

  const reader = response.body!.getReader();

  const readChunk = () => {
    // Read a chunk from the reader
    reader
      .read()
      .then(({ value, done }) => {
        if (done) {
          events.onEnd?.();
          return;
        }

        // Convert the chunk value to a string
        const chunkString = new TextDecoder().decode(value);
        events.onChunk?.(chunkString);

        // Read the next chunk
        readChunk();
      })
      .catch((error) => {
        // Log the error
        console.error(error);
      });
  };
  // Start reading the first chunk
  readChunk();
};

type QPs =
  | Record<string, string | number | boolean | undefined | (string | number | boolean | undefined)[]>
  | URLSearchParams;

export const fetchUploadPresignedUrl = async <T extends Record<string, any> = Record<string, never>>(
  presignedUrlGenerator: string,
  file: File,
  query?: QPs
) => {
  const normalizedFilename = encodeURIComponent(file.name.replace(/\s/g, '-'));
  const presignedUrlData = await fetcher<{ url: string; fields: Record<string, any> } & T>(
    query
      ? makeURLWithQuery(`${presignedUrlGenerator}/${normalizedFilename}`, query)
      : `${presignedUrlGenerator}/${normalizedFilename}`,
    'GET'
  );
  const formData = new FormData();
  Object.entries(presignedUrlData.fields).forEach(([key, value]) => {
    formData.append(key, value);
  });
  formData.append('file', file);
  await fetch(presignedUrlData.url, {
    method: 'POST',
    body: formData,
  });

  return presignedUrlData;
};

export const makeURLWithQuery = (path: string, query: QPs) => {
  if (query instanceof URLSearchParams) {
    query = Object.fromEntries(query.entries());
  }

  const queryStr = Object.entries(query)
    .flatMap(([key, value]) => {
      if (Array.isArray(value)) {
        return value.map((v) => [key, v]);
      } else {
        return [[key, value]];
      }
    })
    .filter(([, value]) => value !== undefined)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');

  if (queryStr) {
    if (path.includes('?')) {
      return `${path}&${queryStr}`;
    } else {
      return `${path}?${queryStr}`;
    }
  } else {
    return path;
  }
};

/**
 * Mutates an item in the `setArrayState` array based on the provided predicate.
 * @param setArrayState - The set state action of the array of items to be mutated.
 * @param predicate - A function that takes an item and returns a boolean indicating whether the item should be mutated.
 * @param itemChanges - The partial item data to be merged with the existing item.
 */
export const mutateArrayState = <T extends Record<string, any>>(
  setArrayState: React.Dispatch<React.SetStateAction<T[]>>,
  predicate: (item: T) => boolean,
  itemChanges: Partial<T>
) => {
  setArrayState((prev) => {
    return prev.map((prevItem) => {
      if (predicate(prevItem)) {
        return {
          ...prevItem,
          ...itemChanges,
        };
      } else {
        return prevItem;
      }
    });
  });
};

export const usePaginatedFetch = <ItemData extends Record<string, any> = Record<string, any>>(
  path: string | null,
  headers?: Record<string, string>,
  rewritePath?: (path: string, items: ItemData[]) => string
) => {
  const isFetchingRef = useRef<boolean>(false);

  const [itemPages, setItemPages] = useState<ItemData[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  // Used for UI only, not for fetching logic
  const [isNoMoreDataState, setIsNoMoreDataState] = useState<boolean>(false);
  const [totalItemNumber, setTotalItemNumber] = useState<number | null>(null);

  const isNoMoreDataRef = useRef<boolean>(false);
  const nextStartIdRef = useRef<string | null>(null);

  const mutate = (cb: (prev: ItemData[]) => ItemData[]) => {
    setItemPages(cb(itemPages));
  };

  const addNextStartIdToPath = (path: string) => {
    if (nextStartIdRef.current) {
      return makeURLWithQuery(path, { start_id: nextStartIdRef.current });
    }

    return path;
  };

  const getNextPage = async (skipRewrite?: boolean) => {
    if (path && !isNoMoreDataRef.current) {
      isFetchingRef.current = true;
      setIsLoading(true);

      let pathToFetch = addNextStartIdToPath(path);
      if (rewritePath && !skipRewrite) {
        pathToFetch = rewritePath(pathToFetch, itemPages);
      }

      try {
        const nextPageRes = await fetcher<{
          items: ItemData[];
          total: number;
          next_start_id: string | null;
          total_items_number?: number;
        }>(pathToFetch, 'GET', undefined, headers);

        // If the path has changed while fetching, we don't want to update the state
        if (isFetchingRef.current) {
          setIsLoading(false);
          nextStartIdRef.current = nextPageRes.next_start_id;
          setIsNoMoreDataState(nextPageRes.next_start_id === null);
          isNoMoreDataRef.current = nextPageRes.next_start_id === null;
          setTotalItemNumber(
            typeof nextPageRes.total_items_number === 'number' ? nextPageRes.total_items_number : null
          );
          setItemPages((prev) => [...prev, ...nextPageRes.items]);
        }

        isFetchingRef.current = false;
      } catch (error) {
        setIsLoading(false);
        isFetchingRef.current = false;
        return;
      }
    }
  };

  useEffect(() => {
    setItemPages([]);
    setIsNoMoreDataState(false);
    setTotalItemNumber(null);
    isNoMoreDataRef.current = false;
    nextStartIdRef.current = null;
    isFetchingRef.current = false;
  }, [path]);

  useEffect(() => {
    if (path) {
      getNextPage(true);
    }
  }, [path]);

  return {
    getNextPage: () => getNextPage(false),
    items: itemPages,
    isLoading,
    mutate,
    mutateItem: (predicate: (item: ItemData) => boolean, itemChanges: Partial<ItemData>) =>
      mutateArrayState(setItemPages, predicate, itemChanges),
    isNoMoreData: isNoMoreDataState,
    totalItemNumber,
  };
};
